import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './auth/authContext';

import Login from './pages/Login';
import Info from './pages/Info';
import SSOLogin from './pages/SSOLogin';
import ChangePassword from './pages/ChangePassword';

// basename="/garita" si estuviera dentro de un directorio

function App() {
  return (
    <main style={{ height: '100vh' }}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route path="/changepassword" element={<ChangePassword />} />

            <Route path="/tokenuser/:token" element={<SSOLogin />} />
            <Route path="/info" element={<Info />} />

            <Route path="*" element={<Login />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </main>
  );
}

export default App;
