import { useEffect } from 'react';

import useAuth from '../hooks/useAuth';

export default function SSOLogin() {
  const auth = useAuth();

  useEffect(() => {
    const token = window.location.pathname.replace('/tokenuser/', '');
    auth.ssologin(token);
    //eslint-disable-next-line
  }, []);

  return (
    <main className="container" style={{ height: '100vh' }}>
      <div className="d-flex justify-content-center align-items-center h-100">
        <h2>Validando datos...</h2>
      </div>
    </main>
  );
}
