import { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import logo from '../images/logo.jpg';
import '../styles/Login.css';

const BASE_URL = process.env.REACT_APP_BASE_BASE_URL;

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({
    username: `${BASE_URL}` === 'https://flex.test/' ? 'garita@yanbal' : '',
    password: `${BASE_URL}` === 'https://flex.test/' ? 'Flex1234' : ''
  });

  const handleChange = ({ target }: any) => {
    setError(false);
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const res = await auth.login(data.username, data.password);

    if (res) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    if (auth.user.isLogged) navigate('/info');

    //eslint-disable-next-line
  }, [auth.user.isLogged]);

  const { username, password } = data;

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="card shadow" style={{ width: '18rem' }}>
        <form onSubmit={handleSubmit}>
          <div className="card-body p-4 d-flex align-items-center flex-column">
            <img src={logo} alt="Flex logo" width="130" />

            {error && (
              <div className="alert alert-danger mt-3 text-center">
                Usuaio o contraseña incorrectos
              </div>
            )}

            <div className="mb-3 mt-3 w-100">
              <label htmlFor="username" className="form-label">
                Usuario
              </label>
              <input
                type="text"
                id="username"
                className="form-control"
                name="username"
                value={username}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3 w-100">
              <label htmlFor="password" className="form-label">
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="card-footer p-3">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary w-100"
            >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
