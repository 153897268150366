import { useEffect, useState } from 'react';

import logo from '../images/logo.jpg';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useRequest from '../hooks/useRequest';

export default function ChangePassword() {
  const auth = useAuth();

  const { reqTokenBase } = useRequest();

  const navigate = useNavigate();

  const [security, setSecurity] = useState(0);

  const [values, setValues] = useState({
    password: '',
    passwordr: ''
  });

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;

    setValues({ ...values, [name]: value });
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);

  function validarContrasena(password: any) {
    // Requerimientos
    const tieneMayuscula = /[A-Z]/.test(password);
    const tieneNumero = /[0-9]/.test(password);
    const tieneCaracterEspecial = /[#$%&_-]+/.test(password);

    // Calcular el porcentaje de cumplimiento
    let porcentaje = 0;
    if (tieneMayuscula) {
      porcentaje += 33.33;
    }
    if (tieneNumero) {
      porcentaje += 33.33;
    }
    if (tieneCaracterEspecial) {
      porcentaje += 33.33;
    }
    if (password === '') {
      porcentaje = 0;
    }

    // Actualizar el estado del porcentaje de cumplimiento
    setSecurity(porcentaje);
  }

  const handleSubmit = async () => {
    setError(null);
    setLoading(true);

    try {
      const { password, passwordr } = values;

      if (password !== passwordr) {
        setError('Las contraseñas deben ser iguales');
        setLoading(false);
        return;
      }

      const tieneMayuscula = /[A-Z]/.test(password);
      const tieneNumero = /[0-9]/.test(password);
      const tieneCaracterEspecial = /[#$%&_-]+/.test(password);

      if (!tieneMayuscula) {
        setError('La contraseña debe contener al menos una letra mayúscula');
        setLoading(false);
        return;
      }

      if (!tieneNumero) {
        setError('La contraseña debe contener al menos un número');
        setLoading(false);
        return;
      }

      if (!tieneCaracterEspecial) {
        setError(
          `La contraseña debe contener al menos un caracter especial igual a: '#', '$', '%', '&', '_', '-'`
        );
        setLoading(false);
        return;
      }

      const res = await reqTokenBase.post('user/password', {
        ...values
      });

      if (res.data.status === 400) {
        setError(res.data.messages.error);
        setLoading(false);
        return;
      }

      auth.setUser((prevData: any) => ({
        ...prevData,
        userData: { ...prevData.userData, passexp: 999 }
      }));

      navigate('/info');

      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setError(error.response?.data.messages.error);
    }
  };

  useEffect(() => {
    if (Number(auth.user.userData.passexp) > 0) navigate('/');

    //eslint-disable-next-line
  }, [auth.user.isLogged]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <form className="card p-3 p-xl-5 shadow bg-body">
        <div className="w-100 mb-4 d-flex justify-content-center">
          <img src={logo} alt="Flex logo" width="200" />
        </div>

        <h4 className="text-dark">Cambiar contraseña</h4>
        <p className="text-dark">
          Tu contraseña ha caducado, es necesario que la renueves para poder
          ingresar
        </p>

        {error && (
          <div
            className="alert alert-danger w-100 mb-4 text-center"
            role="alert"
          >
            {error}
          </div>
        )}

        <div className="form-floating">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            min={6}
            value={values.password}
            onChange={(e) => {
              handleInputChange(e);
              validarContrasena(e.target.value);
            }}
            readOnly={loading ? true : false}
          />
          <label htmlFor="password" className="text-dark">
            Nueva contraseña
          </label>
        </div>

        <div className="progress mt-2 mb-3">
          <div
            className={`progress-bar bg-${
              security < 40 ? 'danger' : security < 70 ? 'warning' : 'success'
            }`}
            role="progressbar"
            style={{ width: `${security}%` }}
            aria-valuenow={security}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>

        <div className="form-floating mb-0">
          <input
            type="password"
            className="form-control"
            id="passwordr"
            name="passwordr"
            min={6}
            value={values.passwordr}
            onChange={handleInputChange}
            readOnly={loading ? true : false}
          />
          <label htmlFor="passwordr" className="text-dark">
            Confirmar contraseña
          </label>
        </div>

        <div className="d-flex w-100 align-items-end mt-3 justify-content-between flex-wrap">
          <button
            onClick={auth.logout}
            type="button"
            className="btn btn-danger"
          >
            Salir
          </button>

          <button
            type="button"
            className="btn btn-primary"
            disabled={loading ? true : false}
            onClick={handleSubmit}
          >
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ width: '1.5rem', height: '1.5rem' }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              'Guardar'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
