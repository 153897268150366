import React, { useState } from 'react';
import useRequest from '../../hooks/useRequest';
import InputSelect from '../inputs/InputSelect';
import InputTextarea from '../inputs/InputTextarea';

export default function FaultForm({ resStatus, setStep, reqType }: any) {
  const { reqToken } = useRequest();

  const [data, setData] = useState({
    fau_int_severity: '',
    fau_text_description: ''
  });
  const [error, setError] = useState({
    fau_int_severity: '',
    fau_text_description: ''
  });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = ({ target }: any) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async () => {
    if (data.fau_int_severity === '') {
      setError({ ...error, fau_int_severity: 'El campo es requerido' });
      return;
    }

    if (data.fau_text_description === '') {
      setError({
        ...error,
        fau_text_description: 'El campo es requerido'
      });
      return;
    }

    setLoading(true);

    try {
      await reqToken.post('administration/faults', {
        ...data,
        bus_int_id: resStatus.datos.info.bus_int_id,
        fau_text_id: resStatus.datos.info['identificación'],
        fau_int_woh: reqType === 1 ? '3' : '4',
        log_usu_int_id: resStatus.datos.info.log_usu_id
      });

      setSuccess(true);

      setTimeout(() => {
        setStep(2);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  if (success) {
    return (
      <div className="card p-3">
        <div className="alert alert-success text-center mb-0">
          Falta guardada con éxito
        </div>
      </div>
    );
  }

  return (
    <div className="card w-75">
      <div className="card-header p-3 d-flex align-items-center justify-content-between">
        <h3 className="mb-0">Agregar falta</h3>

        <button
          disabled={loading}
          onClick={() => setStep(1)}
          type="button"
          className="btn"
        >
          <i className="fas fa-times"></i>
        </button>
      </div>

      <div className="card-body d-flex flex-column align-items-center gap-3 py-5">
        <ul className="list-group">
          <li className="list-group-item mb-0">
            <span className="fw-bold me-2">Identificación:</span>{' '}
            {resStatus.datos.info['identificación']}
          </li>
          <li className="list-group-item mb-0">
            <span className="fw-bold me-2">Empresa:</span>{' '}
            {resStatus.datos.info.empresa}
          </li>
        </ul>

        <InputSelect
          className="w-100"
          label="Severidad"
          id="fau_int_severity"
          value={data.fau_int_severity}
          name="fau_int_severity"
          onChange={handleChange}
          isInvalid={error.fau_int_severity}
          options={[
            { value: '1', name: 'Baja' },
            { value: '2', name: 'Media' },
            { value: '3', name: 'Alta' }
          ]}
          placeholder="Selecciona una opción"
        />

        <InputTextarea
          className="w-100"
          label="Descripción"
          id="fau_text_description"
          value={data.fau_text_description}
          name="fau_text_description"
          onChange={handleChange}
          isInvalid={error.fau_text_description}
        />
      </div>

      <div className="card-footer d-flex justify-content-between p-3">
        <button
          onClick={() => setStep(2)}
          type="button"
          className="btn btn-secondary"
          disabled={loading}
        >
          Volver
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-success"
          disabled={loading}
        >
          Guardar
        </button>
      </div>
    </div>
  );
}
