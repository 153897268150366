import { createContext, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useRequest from '../hooks/useRequest';

const defaultUser = {
  isLogged: false,
  access_token: '',
  expires_in: 0,
  token_type: '',
  scope: null,
  refresh_token: '',
  userData: {
    id: '',
    name: '',
    bus_name: '',
    passexp: ''
  },
  isAdmin: '',
  hasTags: ''
};

interface defaultState {
  user: {
    isLogged: boolean;
    access_token: string;
    expires_in: number | string;
    token_type: string;
    scope: null;
    refresh_token: string;
    userData: {
      id: string;
      name: string;
      bus_name: string;
      passexp: string;
    };
    isAdmin: string;
    hasTags: string;
  };
  login: (
    username: string,
    password: string
  ) => Promise<
    | 'Este usuario no tiene acceso'
    | 'Acceso denegado, correo o contraseña incorrectos'
    | undefined
  >;
  ssologin: (token: string) => void;
  logout: () => void;
  setUser: (data: any) => void;
}

export const AuthContext = createContext({} as defaultState);

export const AuthProvider: FC = ({ children }) => {
  const { reqLogin } = useRequest();
  const navigate = useNavigate();

  const [user, setUser] = useState(
    sessionStorage.getItem('user-data')
      ? {
          ...defaultUser,
          userData: {
            ...(JSON.parse(sessionStorage.getItem('user-data')!) ||
              defaultUser.userData)
          }
        }
      : defaultUser
  );

  const login = async (username: string, password: string) => {
    try {
      const res = await reqLogin.post('user/login', {
        username,
        password,
        grant_type: 'password'
      });

      if (!res.data.body.hasTags?.includes('garita')) {
        return 'Este usuario no tiene acceso';
      }

      if (Number(res.data.body.userData.passexp) <= 0) {
        navigate('/changepassword');
      }

      sessionStorage.setItem(
        'user-data',
        JSON.stringify(res.data.body.userData)
      );

      sessionStorage.setItem('access-token', res.data.body.access_token);
      sessionStorage.setItem('refresh-token', res.data.body.refresh_token);

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(username));

      // user last Login (now)
      sessionStorage.setItem('dull', btoa(new Date().getTime().toString()));

      setUser({ ...res.data.body, isLogged: true });

      setTimeout(() => {
        logout();
      }, 3300000);
    } catch (error) {
      console.log(error);
      return 'Acceso denegado, correo o contraseña incorrectos';
    }
  };

  const ssologin = async (token: string) => {
    try {
      const res = await reqLogin.post('user/ssouser', {
        d: token
      });

      if (!res.data.body.hasTags?.includes('garita')) {
        navigate('/login', { replace: true });
        throw new Error('Sin los accesos requerido');
      }

      sessionStorage.setItem(
        'user-data',
        JSON.stringify(res.data.body.userData)
      );

      sessionStorage.setItem('access-token', res.data.body.access_token);
      sessionStorage.setItem('refresh-token', res.data.body.refresh_token);

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(res.data.body.userData.user));

      // user last Login (now)
      sessionStorage.setItem('dull', btoa(new Date().getTime().toString()));

      setUser({ ...res.data.body, isLogged: true });

      navigate('/info', { replace: true });

      setTimeout(() => {
        logout();
      }, 3300000);
    } catch (error) {
      console.log(error);
      navigate('/login', { replace: true });
    }
  };

  const refreshToken = async () => {
    const thisLoginDate = new Date().getTime();
    const refreshToken = sessionStorage.getItem('refresh-token');

    let lastLoginDate = sessionStorage.getItem('dull');
    //@ts-ignore
    lastLoginDate = atob(lastLoginDate);

    // let userData = sessionStorage.getItem("userData");
    // userData = JSON.parse(userData);

    let email = sessionStorage.getItem('emuecb');

    // if no email in storage, need to login
    if (!email) return;

    // set email as decoded string
    email = atob(email);

    // if email, need to compare last token date against token now, time cant be greater than 3600 seconds or 1 hour
    //@ts-ignore
    const timeDifference = (thisLoginDate - lastLoginDate) / 1000;

    // if token expired, require login
    if (timeDifference > 3600) return navigate('/');

    // set context user with prev userData info from storage to allow instant redirect to home while refresh full data with the API call
    // setUser({ userData });

    try {
      const res = await reqLogin.post('user/login', {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        username: email
      });

      if (!res.data.body.hasTags.includes('garita')) {
        return setUser(defaultUser);
      }

      setUser(res.data.body);

      sessionStorage.setItem('access_token', res.data.body.access_token);
      sessionStorage.setItem('refresh_token', res.data.body.refresh_token);
      sessionStorage.setItem(
        'userData',
        JSON.stringify(res.data.body.userData)
      );

      // email user enconde base 64
      sessionStorage.setItem('emuecb', btoa(email));

      // user last Login (now)
      // @ts-ignore
      sessionStorage.setItem('dull', btoa(new Date().getTime()));
    } catch (error) {
      console.log(error);
    }

    //eslint-disable-next-line
  };

  const logout = () => {
    navigate('/');

    setUser(defaultUser);

    sessionStorage.clear();
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      logout();
    }, 1000 * 60 * 60);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    refreshToken();
    //eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, ssologin, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
