import React from 'react';

export default function RequestForm({
  handleSubmit,
  error,
  dni,
  serie,
  empresa,
  data,
  handleChange,
  loading
}: any) {
  return (
    <div className="card w-75">
      <div className="card-header p-3">
        <h3 className="text-center w-100 mb-0">Consultar</h3>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="card-body d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 py-5">
          <div className="flex-grow-1">
            <label htmlFor="dni" className="form-label">
              Trabajador
            </label>
            <input
              type="text"
              id="dni"
              className={`form-control ${error.dni && 'is-invalid'}`}
              name="dni"
              value={dni}
              onChange={handleChange}
              disabled={data.serie || data.empresa ? true : false}
            />
            {error.dni && <div className="invalid-feedback">{error.dni}</div>}
          </div>
          <div className="flex-grow-1">
            <label htmlFor="serie" className="form-label">
              Equipo
            </label>
            <input
              type="text"
              id="serie"
              className={`form-control ${error.serie && 'is-invalid'}`}
              name="serie"
              value={serie}
              onChange={handleChange}
              disabled={data.dni || data.empresa ? true : false}
            />
            {error.serie && (
              <div className="invalid-feedback">{error.serie}</div>
            )}
          </div>
          <div className="flex-grow-1">
            <label htmlFor="empresa" className="form-label">
              Empresa [ RUC ]
            </label>
            <input
              type="text"
              id="empresa"
              className={`form-control ${error.empresa && 'is-invalid'}`}
              name="empresa"
              value={empresa}
              onChange={handleChange}
              disabled={data.dni || data.serie ? true : false}
            />
            {error.empresa && (
              <div className="invalid-feedback">{error.empresa}</div>
            )}
          </div>
        </div>

        <div className="card-footer p-3 d-flex justify-content-center">
          <button
            type="submit"
            className="btn btn-primary px-5"
            disabled={loading}
          >
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
}
