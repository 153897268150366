import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import useRequest from '../hooks/useRequest';

import Header from '../components/layout/Header';

import '../styles/SituacionDocumental.css';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import RequestForm from '../components/information/RequestForm';
import RequestResult from '../components/information/RequestResult';
import FaultForm from '../components/information/FaultForm';

const defaultStatus = {
  dni: '',
  serie: '',
  empresa: ''
};

export default function SituacionDocumental() {
  const { reqTokenBase } = useRequest();
  const navigate = useNavigate();
  const auth = useAuth();

  const [data, setData] = useState(defaultStatus);
  const [error, setError] = useState(defaultStatus);
  const [resStatus, setResStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reqType, setReqType] = useState(1);

  const [step, setStep] = useState(1);

  const handleChange = ({
    target
  }: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setData({ ...data, [target.name]: target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError(defaultStatus);

    if (!data.dni && !data.serie && !data.empresa) {
      return setError({
        ...error,
        dni: 'Uno de estos campos es necesario',
        serie: 'Uno de estos campos es necesario',
        empresa: 'Uno de estos campos es necesario'
      });
    }

    setLoading(true);

    const reqField = data.dni
      ? `tra_varchar_dni=${data.dni}`
      : data.serie
      ? `equ_varchar_serie=${data.serie}`
      : `ruc=${data.empresa}`;
    const reqType = data.dni
      ? 'trabajador'
      : data.serie
      ? 'equipos'
      : 'empresa';
    setReqType(data.dni ? 1 : data.dni ? 2 : 3);

    try {
      const res = await reqTokenBase.get(
        `garita/estado?${reqField}&consulta=${reqType}&o=azul`
      );

      setResStatus(res.data);
      setLoading(false);
      setStep(2);
    } catch (error) {
      setResStatus(false);
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!auth.user.isLogged) navigate('/');

    //eslint-disable-next-line
  }, [auth.user.isLogged]);

  const { dni, serie, empresa } = data;

  return (
    <section
      style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Header />

      <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
        {step === 1 && (
          <RequestForm
            handleSubmit={handleSubmit}
            error={error}
            dni={dni}
            serie={serie}
            empresa={empresa}
            data={data}
            handleChange={handleChange}
            loading={loading}
          />
        )}

        {step === 2 && (
          <RequestResult
            setStep={setStep}
            resStatus={resStatus}
            reqType={reqType}
            clear={() => {
              setStep(1);
              setData(defaultStatus);
            }}
          />
        )}

        {step === 3 && (
          <FaultForm
            setStep={setStep}
            resStatus={resStatus}
            reqType={reqType}
          />
        )}
      </div>
    </section>
  );
}
