import useAuth from '../../hooks/useAuth';

import logo from '../../images/flex---navbar.jpg';

export default function Header() {
  const auth = useAuth();

  return (
    <header>
      <nav className="navbar bg-light shadow border-bottom">
        <div className="container-fluid">
          <img src={logo} alt="Flex logo" width="100" />

          <p className="fw-bold text-center m-0">
            La contraseña expira en {auth.user?.userData.passexp} días
          </p>

          <div className="d-flex justify-content-end align-items-center gap-2">
            <input
              className="form-control"
              type="text"
              value={auth.user.userData.name}
              readOnly
              style={{ cursor: 'pointer' }}
            />
            <button
              onClick={auth.logout}
              type="button"
              className="btn btn-danger"
            >
              Salir
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
